/** @format */

import {
  bindable,
  observable
} from 'aurelia-framework';
import geoService from 'services/api/geoService';
import poiService from 'services/api/areasService';
import uiService from 'services/uiService';

import eventService, {
  EventsList
} from 'services/eventService';
import './poi-list.scss';

export class PoiList {
  @bindable closed;
  @bindable filter;
  @bindable showAddressAction;
  @bindable showAddressList;
  @bindable onItemSelected;
  @bindable onGroupSelected;
  @bindable onAddressSelected;

  @observable poiList;

  constructor() {
    this.geoService = geoService;
    this.poiService = poiService;
    this.uiService = uiService;

    this.loading = false;
    this.poiList = [];
    this.addresses = [];
    this.showAddressAction = false;
    this.showAddressList = false;
    this.onItemClick = this.onItemClick.bind(this);
    this.onGroupClick = this.onGroupClick.bind(this);
    this.onAddressClick = this.onAddressClick.bind(this);

    this.preserveScroll = true;
  }

  filterChanged(value) {
    this.addresses = [];
    this.showAddressList = false;
    this.showAddressAction = (this.filter && this.filter.length >= 3) ? true : false;

    if (!value || !value.length) this.checkPreserveScroll(true);
  }

  attached() {
    eventService.subscribe(EventsList.POICreated, this.onPOICreated);
    eventService.subscribe(EventsList.POIChanged, this.onPOIChanged);

    this.loading = true;
    this.poiService.get().then(pois => {
      this.poiList = pois.map(this.poiToList);
      this.loading = false;
    });
  }

  detached() {
    eventService.unsubscribe(EventsList.POICreated, this.onPOICreated);
    eventService.unsubscribe(EventsList.POIChanged, this.onPOIChanged);
  }

  poiToList(poi) {
    let {
      id,
      name,
      formattedAddress,
      code,
      groupName,
      groupId
    } = poi;

    return {
      poi: poi, //original class
      //
      id,
      name,
      groupName,
      groupId,
      code,
      formattedAddress,
      //
      selected: false,
      icon: 'fi-map-marker',
    };
  }

  onItemClick(poi) {
    if (this.onItemSelected) {
      this.checkPreserveScroll();
      this.onItemSelected(poi);
    }
  }

  onGroupClick(group) {
    if (this.onGroupSelected && group) {
      let {
        id,
        name
      } = group;
      this.onGroupSelected({
        id,
        name,
        items: group.items.map(i => {
          return i.poi;
        })
      });
    }
  }

  onPOICreated = poi => {
    let newList = [this.poiToList(poi)];
    this.poiList = this.poiList.concat(newList);
  };

  onPOIChanged = poi => {
    if (!poi) return;
    const listPoi = this.poiList.find(x => x.id === poi.id);

    if (!listPoi) return;

    //only name is shown on list, so update only that
    listPoi.name = poi.name;
    listPoi.formattedAddress = poi.formattedAddress;
  };

  showAddressListChanged() {
    if (this.showAddressList) {
      this.loading = true;
      this.geoService
        .geoCoding(this.filter)
        .then(addresses => {
          this.addresses = addresses;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    } else {
      this.addresses = [];
    }
  }

  onAddressClick(address) {
    if (this.onAddressSelected) {
      this.onAddressSelected(address);
    }
  }

  checkPreserveScroll(restore = false) {
    if (this.preserveScroll && this.listEl) {
      restore && this._lastScrollTop ?
        setTimeout(() => (this.listEl.scrollTop = this._lastScrollTop), 0) :
        (this._lastScrollTop = this.listEl.scrollTop);
    }
  }
}
